@import '@/assets/styles/_global.scss';

.Button {
  &-icon {
    .Icon {
      width: 2rem;
      height: 2rem;

      svg {
        transition: $common-transition;
      }
    }
  }

  &.only-icon {
    .ant-btn {
      width: 4.8rem;
      padding: 0;

      &.ant-btn-sm {
        width: 4rem;
      }
    }
  }

  .ant-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1;
    border-radius: .8rem;
    padding: 1.2rem;
    height: 4.8rem;
    column-gap: .4rem;
    letter-spacing: 0.04rem;

    &:disabled {
      opacity: 0.35 !important;
    }

    &.ant-btn-lg {
      column-gap: 1.6rem;
      height: 5.6rem;
    }

    &.ant-btn-sm {
      column-gap: 0.4rem;
      height: 4rem;
    }
  }

  &.reverse {
    .ant-btn {
      flex-direction: row-reverse;
    }
  }

  &.GENERAL_FORM {
    .ant-btn {
      border-color: $silver;
      background: $white;
      color: $mine-shaft;

      &:hover {
        border-color: $purple-heart;
        color: $purple-heart;

        svg {
          stroke: $purple-heart;
        }
      }
    }
  }

  &.PURPLE {
    .ant-btn {
      border-color: $purple-heart;
      background: $purple-heart;
      color: $white;

      &:hover {
        border-color: darken($purple-heart, 5%);
        background: darken($purple-heart, 5%);
      }
    }
  }

  &.DANGER {
    .ant-btn {
      border-color: $pomegranate;
      background: $pomegranate;
      color: $white;

      &:hover {
        border-color: darken($pomegranate, 5%);
        background: darken($pomegranate, 5%);
      }
    }
  }

  &.DANGER_TRANSPARENT {
    .ant-btn {
      border-color: lighten($pomegranate, 35%);
      background: lighten($pomegranate, 35%);
      color: $pomegranate;

      &:hover {
        border-color: lighten($pomegranate, 30%);
        background: lighten($pomegranate, 30%);
      }
    }
  }

  &.PURPLE_TRANSPARENT {
    .ant-btn {
      border-color: $white-lilac;
      background: $white-lilac;
      color: $purple-heart;

      &:hover {
        border-color: darken($white-lilac, 5%);
        background: darken($white-lilac, 5%);
      }
    }
  }

  &.OUTLINE_PURPLE {
    .ant-btn {
      border-color: $purple-heart;
      background: $white;
      color: $purple-heart;

      &:hover {
        border-color: $purple-heart;
        background: $white-lilac;
      }
    }
  }
}
