@import '@/assets/styles/_global.scss';

.Header {
  background: $white;
  box-shadow: 0 0 4px rgba($black, .2);

  &-menu {
    flex: 0 0 4.8rem;
    max-width: 4.8rem;
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;
    margin-right: 1.2rem;
    @include centerOriginImage;
    display: none;
    border-radius: .8rem;

    @media (max-width: 991px) {
      display: flex;
    }
  }

  &-wrapper {
    padding: 0.4rem 2.4rem;
    height: 6rem;

    @media (max-width: 991px) {
      padding: 0.4rem 1.6rem 0.4rem 1.2rem;
      height: 6.4rem;
    }
  }

  &-title {
    position: relative;
    font-size: 2.4rem;
    font-weight: 700;
    color: $tundora;
    line-height: 1.3;
    text-transform: capitalize;

    &::before {
      position: absolute;
      left: 0;
      top: calc(100% + 1.4rem);
      transform: translateY(-100%);
      content: '';
      width: 8rem;
      height: .4rem;
      border-radius: .4rem .4rem 0 0;
      background: $purple-heart;

      @media (max-width: 991px) {
        top: calc(100% + 1.6rem);
      }
    }
  }

  &-account {
    column-gap: 1.2rem;
    padding: .8rem 1.6rem;
    border-radius: 3.5rem;
    background: $white-lilac;
    cursor: pointer;

    &-title {
      font-size: 1.4rem;
      color: $mine-shaft;
      font-weight: 500;

      @media (max-width: 575px) {
        display: none;
      }
    }

    &-arrow {
      margin-left: -.8rem;
      margin-top: .2rem;

      @media (max-width: 575px) {
        margin-left: 0;
      }

      .Icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &-branch {
    column-gap: 0.8rem;
    padding: .8rem 1.6rem;
    border-radius: 3.5rem;
    cursor: pointer;

    @media (max-width: 575px) {
      padding: .8rem;
    }

    &-title {
      font-size: 1.4rem;
      color: $mine-shaft;
      font-weight: 500;

      @media (max-width: 575px) {
        display: none;
      }
    }

    &-icon {
      .Icon {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &-arrow {
      margin-top: .2rem;

      .Icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}
