@import '@/assets/styles/_global.scss';

.Admin {
  &.visible-menu {
    .Admin {
      &-sidebar {
        pointer-events: unset;
        transform: translateX(0%);

        &-overlay {
          pointer-events: unset;
          opacity: 1;
        }
      }
    }
  }

  &-sidebar {
    position: fixed;
    width: 24rem;
    height: 100%;
    z-index: 5;

    @media (max-width: 991px) {
      transition: $common-transition;
      transform: translateX(-100%);
      pointer-events: none;
    }

    &-overlay {
      @include fullAbsolute;
      position: fixed;
      z-index: 4;
      background: rgba($black, 0.4);
      backdrop-filter: blur(2px);
      transition: $common-transition;
      opacity: 0;
    backdrop-filter: blur(2px);
      pointer-events: none;
    }
  }

  &-header {
    position: fixed;
    top: 0;
    left: 24rem;
    width: calc(100% - 24rem);
    z-index: 4;

    @media (max-width: 991px) {
      left: 0;
      width: 100%;
    }
  }

  &-body {
    position: relative;
    padding: calc(6rem + 2.4rem) 2.4rem 2.4rem calc(24rem + 2.4rem);
    min-height: 100vh;
    background: rgba($alto, 0.2);

    @media (max-width: 991px) {
      padding-left: 2.4rem;
    }
  }

  &-back {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: $dove-gray;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: $purple-heart;

      .Icon {
        svg {
          stroke: $purple-heart;
        }
      }
    }

    .Icon {
      width: 2.8rem;
      height: 2.8rem;
      margin-top: .2rem;

      svg {
        transition: $common-transition;
      }
    }
  }
}
