@import '~antd/dist/antd.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './normalize';
@import './reset';
@import './fonts';
@import './colors';
@import './constants';
@import './mixins';

* {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

html {
  font-size: 10px;

  @media (max-width: 1200px) {
    font-size: 9px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
  }
}

.container {
  max-width: 120rem;
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.nowrap {
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.pointer-events-none {
  pointer-events: none;
}

.ant-form {
  .ant-form-item {
    margin-bottom: 0;
  }
}


a {
  color: $purple-heart;

  &:hover {
    color: lighten($purple-heart, 10%);
  }
}

.ant-form {
  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 2.4rem;
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 1.3rem;
    margin-top: .4rem;

    .ant-form-item-explain-error {
      color: $pomegranate;
    }
  }

  .ant-form-item-has-error {
    &>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content {
      &>.FormField {
        border-color: $pomegranate !important;

        &>.FormField-label {
          color: $pomegranate !important;
        }
      }
    }
  }
}


.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      padding: 1rem;
      background: $tundora;
      border-radius: .8rem;
      color: $white;
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: 0.015em;
    }
  }
}

.ant-modal-mask {
  backdrop-filter: blur(2px)
}

.ant-notification {
  pointer-events: none;
}

.Notification {
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  border-radius: .8rem;
  overflow: unset;
  padding: 1.2rem 1.6rem;

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: flex-start;
  }

  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-close {
    display: none;
  }

  .ant-notification-notice-description {
    font-size: 1.4rem;
    color: $tundora;
    margin-left: 1.2rem !important;
    margin-top: 0.4rem;
    text-transform: capitalize;
  }

  .ant-notification-notice-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 3.2rem;
    flex: 0 0 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
  }

  &.ant-notification-notice-success {
    background: lighten($apple, 53%);

    .ant-notification-notice-description {
      color: $apple;
    }
  }

  &.ant-notification-notice-error {
    background: lighten($pomegranate, 40%);

    .ant-notification-notice-description {
      color: $pomegranate;
    }
  }
}

.word-break-all {
  word-break: break-all;
}

.ellipsis-1 {
  @include ellipsis(1);
}

.ellipsis-2 {
  @include ellipsis(2);
}

.text-capitalize {
  text-transform: capitalize;
}

.pre-wrap {
  white-space: pre-wrap;
}


.CustomSelectionRender {
  &-dropdown {
    &-item {
      padding: 1.2rem 1.6rem;
      font-size: 1.4rem;
      cursor: pointer;
      transition: $common-transition;
      column-gap: 1rem;

      &-info {
        flex: 1;

        &-title {
          font-size: 1.4rem;
          color: $mine-shaft;
          font-weight: 500;
          @include ellipsis(1);
        }

        &-description {
          color: $dove-gray;
          font-size: 1.2rem;
          @include ellipsis(1);
        }
      }

      &:hover,
      &.active {
        color: $purple-heart;
        background: $white-lilac;
      }

      &.active {
        &:hover {
          color: darken($purple-heart, 5%);
          background: darken($white-lilac, 5%);
        }
      }
    }
  }
}
