@import '@/assets/styles/_global.scss';

.SideBar {
  padding: 3.2rem 1.2rem;
  background: $white;
  box-shadow: 0 0 4px rgba($black, .2);
  height: 100%;

  &-logo {
    position: relative;
    width: 11rem;
    margin: 0 auto;
    @include centerOriginImage;

    &-version {
      font-size: 1rem;
      color: $selective-yellow;
      position: absolute;
      font-weight: 700;
      top: 100%;
      right: 0;
    }
  }

  .ant-collapse {
    background: transparent;
    border: none;
    border-radius: 0;

    &>.ant-collapse-item {
      border-bottom: none;

      &>.ant-collapse-header {
        padding: 0;
      }
    }

    .ant-collapse-content {
      border-top: none;
      background: transparent;

      &>.ant-collapse-content-box {
        padding: 0.8rem 1.2rem 0.8rem;
      }
    }
  }

  .ant-collapse-item-active {
    background: rgba($amber, 0.15);

    &>.ant-collapse-content-box .ant-collapse-header,
    &>.ant-collapse-header {
      .SideBar-list-item {
        background: rgba($amber, 0.15);

        &-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-list {
    margin-top: 3.2rem !important;
    height: calc(100vh - 3.2rem - 2.8rem - 3.2rem - 3.2rem);
    overflow: auto;

    .ant-collapse-item {
      transition: $common-transition;
      border-radius: .8rem;

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }
    }

    &-item {
      padding: 1.2rem 1.6rem;
      border-radius: .8rem;
      column-gap: 1.2rem;
      cursor: pointer;
      flex: 1;
      transition: $common-transition;

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }

      &:hover,
      &.active {
        background: $white-lilac !important;

        .SideBar-list-item {
          &-title {
            color: $purple-heart;
          }
        }

        .Icon {
          svg {
            stroke: $purple-heart;
          }
        }
      }

      .Icon {
        width: 100%;
        height: 100%;

        svg {
          transition: $common-transition;
        }
      }

      &-arrow {
        flex: 0 0 1.6rem;
        max-width: 1.6rem;
        height: 1.6rem;
        transition: $common-transition;
      }

      &-icon {
        flex: 0 0 2.4rem;
        max-width: 2.4rem;
        height: 2.4rem;
      }

      &-title {
        flex: 1;
        font-size: 1.4rem;
        color: $dove-gray;
        font-weight: 500;
        transition: $common-transition;
        user-select: none;
        @include ellipsis(1);
      }
    }
  }
}
