@import '@/assets/styles/_global.scss';

.Tags {
  column-gap: 0.4rem;
  row-gap: 0.4rem;

  &.no-style {
    .Tags-item {
      padding: 0 !important;
      min-height: auto !important;
      background: none !important;
    }
  }

  .Avatar {
    margin-left: -.4rem;
  }

  &.ellipsis {
    .Tags-item {
      span {
        @include ellipsis(1);
      }
    }
  }

  &-item {
    column-gap: .4rem;
    padding: .4rem 1.2rem;
    border-radius: 3.5rem;
    background: rgba($alto, 0.5);
    font-size: 1.2rem;
    min-height: 3.2rem;
    line-height: 1;
    color: $mine-shaft;

    &.disabled {
      opacity: 0.5;
    }

    span {
      text-align: left;
      line-height: 1.6;
    }

    &.WARNING {
      background: rgba($selective-yellow, 0.15);
      color: $selective-yellow;
    }

    &.DANGER {
      background: rgba($pomegranate, 0.1);
      color: $pomegranate;
    }

    &.SUCCESS {
      background: rgba($apple, 0.1);
      color: $apple;
    }

    &.NORMAL {
      background: rgba($cerulean, 0.1);
      color: $cerulean;
    }

    &.onclick {
      transition: $common-transition;
      cursor: pointer;

      &:hover {
        background: $white-lilac;
        color: $purple-heart;

        svg {
          stroke: $purple-heart;
        }
      }
    }

    &-icon {
      margin-left: -.4rem;

      .Icon {
        width: 2rem;
        height: 2rem;

        svg {
          transition: $common-transition;
        }
      }
    }
  }
}
