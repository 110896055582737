@import '@/assets/styles/_global.scss';

.DropdownMenu {
  .ant-dropdown-open {
    .Button {
      &.GENERAL_FORM {
        .ant-btn {
          border-color: $purple-heart;
          color: $purple-heart;
        }
      }
    }
  }

  &-wrapper {
    cursor: pointer;
  }

  &-overlay {
    padding: .4rem 0;
    background: $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    border-radius: .8rem;
    overflow: hidden;
  }

  &-list {
    &-item {
      padding: 1.2rem 1.6rem;
      font-size: 1.4rem;
      cursor: pointer;
      transition: $common-transition;
      font-weight: 500;
      column-gap: 1.2rem;
      color: $dove-gray;
      min-width: 16rem;

      &.danger {
        color: $pomegranate;

        .Icon {
          svg {
            stroke: $pomegranate;
          }
        }

        &:hover,
        &.active {
          color: $pomegranate;
          background: rgba($pomegranate, 0.1);

          .Icon {
            svg {
              stroke: $pomegranate;
            }
          }
        }
      }

      &-icon {
        flex: 0 0 2.4rem;
        max-width: 2.4rem;
        height: 2.4rem;

        .Icon {
          width: 100%;
          height: 100%;

          svg {
            transition: $common-transition;
          }
        }
      }

      &:hover,
      &.active {
        color: $purple-heart;
        background: $white-lilac;

        .Icon {
          svg {
            stroke: $purple-heart;
          }
        }
      }
    }
  }
}
