@import '@/assets/styles/_global.scss';

.Modal {
  .ant-modal-content {
    border-radius: 1.6rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);

    .ant-modal-body {
      padding: 2.4rem;
    }
  }

  .ant-modal-close {
    top: 1.2rem;
    right: 1.2rem;

    .ant-modal-close-x {
      width: 2.8rem;
      height: 2.8rem;

      .Icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-header {
    padding: 1.6rem 2.4rem;
    margin: -2.4rem -2.4rem 2.4rem;
    border-bottom: 1px solid $alto;

    &-title {
      font-size: 2rem;
      text-align: center;
      color: $mine-shaft;
      font-weight: 700;
    }
  }

  &-text {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &-footer {
    border-top: 1px solid $alto;
    padding: 1.6rem 2.4rem;
    margin: 2.4rem -2.4rem -2.4rem;
    column-gap: 1.6rem;

    .Button {
      min-width: 10rem;
    }
  }
}
