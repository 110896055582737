/*
- Please follow this tool to name your color, then please define the color in src/common-types/ui.ts also
  https://chir.ag/projects/name-that-color/#6195ED

- If colors same name, please add suffix. Ex: persian-blue, persian-blue-dark, persian-blue-light, ...
*/

$white: #ffffff;
$black: #000000;

$white: #fff;
$black: #000;

$amber: #FFC107;
$purple-heart: #673AB7;
$mine-shaft: #1F1F1F;
$tundora: #454545;
$cerulean: #03A9F4;
$selective-yellow: #FFB300;
$silver-chalice: #cccccc;
$gray: #888888;
$white-lilac: #EDE7F6;
$prelude: #D1C4E9;
$fruit-sald: #4CAF50;
$panache: #E8F5E9;
$silver: #cccccc;
$dove-gray: #6E6E6E;
$emperor: #555555;
$cold-purple: #B39DDB;
$buttermilk: #FFECB3;
$mustard: #FFD54F;
$gallery: #EAEAEA;
$alto: #D9D9D9;
$pomegranate: #F44336;
$apple: #43A047;
$catskill-white: #e1eaf2;
