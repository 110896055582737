
@mixin ellipsis($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin fullAbsolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerScaleImage {
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: auto;
  }
}

@mixin centerOriginImage {
  display: flex;

  svg,
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
    margin: auto;
  }
}

@mixin styleScrollBar() {
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    height: 30px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.05);
    border: 16px;
    height: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1b1e24;
    border: 2px solid #1b1e24;
  }
}
