@import '@/assets/styles/_global.scss';

.Auth {
  &-header {
    position: fixed;
    background: $white;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 4px rgba($black, .2);
    z-index: 5;

    &-logo {
      max-width: 10.4rem;
      @include centerOriginImage;
    }

    &-wrapper {
      height: 6.4rem;
    }
  }

  &-form {
    max-width: 48rem;
    padding: 1.6rem 6.4rem !important;
    margin: 2.4rem auto 0 !important;

    &-header {
      margin-bottom: 3.2rem;
    }
  }

  &-title {
    text-align: center;
    color: $mine-shaft;
    font-size: 2.8rem;
    line-height: 1.3;
  }

  &-description {
    text-align: center;
    line-height: 1.5;
    color: $emperor;
    font-size: 1.6rem;

    &.bold {
      font-weight: 600;
      color: $mine-shaft;
    }

    &.small {
      font-size: 1.4rem;
      color: $dove-gray;
    }
  }

  &-link {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
    color: $purple-heart;
  }

  &-body {
    padding: 6.4rem 0;
  }

  &-tooltip {
    column-gap: .8rem;

    .Icon {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &-avatar {
    .Avatar {
      margin: 0 auto .8rem;
    }
  }
}
